<template>
  <section class="login-content">
    <div class="container h-100">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-xl-5 col-lg-5 col-md-8">
          <div class="card">
            <div class="card-body">
              <div class="auth-logo">
                <!-- <h2 class="text-primary">
                  <strong>Werkstattmanager</strong>
                </h2> -->
                <img
                  src="@/assets/images/logo.png"
                  class="img-fluid rounded-normal"
                  alt="logo"
                />
              </div>
              <h2 class="mb-2 text-center">{{ $t("authMsgs.login") }}</h2>
              <p class="text-center">{{ $t("authMsgs.topText") }}</p>

              <div class="mt-3 row align-items-center justify-content-center">
                <div class="form-group col-xl-4 col-lg-6 col-md-4 col-sm-4">
                  <select
                    class="form-control"
                    v-model="selectedLang"
                    @change="changeLang"
                  >
                    <option :value="LANG_ABBREVIATIONS.EN">
                      {{ $t("langNames.english") }}
                    </option>
                    <option :value="LANG_ABBREVIATIONS.DE">
                      {{ $t("langNames.german") }}
                    </option>
                  </select>
                </div>
              </div>

              <form @submit.prevent="onSubmit()">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>{{ $t("email") }}</label>
                      <input
                        class="form-control"
                        type="email"
                        placeholder="example@company.com"
                        v-model="email"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>{{ $t("password") }}</label>
                      <input
                        class="form-control"
                        type="password"
                        placeholder="********"
                        v-model="password"
                        required
                      />
                    </div>
                  </div>

                  <!-- Link to forgot password. -->
                  <div class="col-lg-12">
                    <router-link
                      :to="{ name: 'recover-password' }"
                      class="text-primary float-right"
                      >{{ $t("authMsgs.forgotPass") }}</router-link
                    >
                  </div>
                </div>

                <div v-if="error" class="alert alert-danger mt-4" role="alert">
                  {{ $t("authMsgs.invalidEmailPass") }}
                </div>

                <!-- <div class="d-flex justify-content-between align-items-center"> -->
                <div class="text-center mt-3">
                  <b-button
                    type="submit"
                    tag="button"
                    class="btn-rounded btn-block"
                    variant="primary"
                    :disabled="loading"
                  >
                    <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                    <span v-else>{{ $t("authMsgs.login") }}</span>
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  LANG_ABBREVIATIONS,
  TOAST_OPTIONS,
  USER_ROLES,
} from "../../common/constants";

export default {
  name: "SignIn",

  data() {
    return {
      LANG_ABBREVIATIONS,

      email: "",
      password: "",
      userId: "",
      loading: false,
      error: false,

      selectedLang: LANG_ABBREVIATIONS.EN,
      isLangChanged: false,
    };
  },

  methods: {
    ...mapActions([
      "login",
      "changeCurrLang",
      "updateResetPasswordTokenInvalid",
    ]),

    async onSubmit() {
      this.loading = true;
      this.error = false;
      try {
        const resp = await this.login({
          email: this.email,
          password: this.password,
        });
        if (!this.isLangChanged) {
          this.selectedLang = resp.user.language;
        }
        this.changeLang();

        this.makeToast(
          "success",
          this.$t("authMsgs.loginSuccessMsg"),
          this.$t("generalMsgs.success")
        );

        this.loading = false;

        // Redirect user to relevant homepage.
        if (
          [
            USER_ROLES.ADMIN,
            USER_ROLES.OFFICE_WORKER,
            USER_ROLES.PROJECT_MANAGER,
          ].includes(resp.user.role)
        ) {
          this.$router.push({ name: "order-overview" });
        } else {
          this.$router.push({ name: "list-bills" });
        }
      } catch (error) {
        this.error = true;
      }
      this.loading = false;
    },

    makeToast(variant = null, msg, title, toaster) {
      this.$bvToast.toast(msg, {
        title,
        variant: variant,
        solid: true,
        ...(toaster && { toaster }),
      });
    },

    changeLang() {
      this.isLangChanged = true;
      this.$i18n.locale = this.selectedLang;
      this.changeCurrLang(this.selectedLang);
    },
  },

  computed: {
    ...mapGetters(["getCurrLanguage", "getResetPassTokenInvalid"]),
  },

  mounted() {
    this.selectedLang = this.getCurrLanguage;
    if (this.getResetPassTokenInvalid === TOAST_OPTIONS.INVALID_TOKEN) {
      this.makeToast(
        "danger",
        this.$t("errorMsgs.invalidResetPassToken"),
        this.$t("errorMsgs.error"),
        "b-toaster-top-center"
      );
    } else if (
      this.getResetPassTokenInvalid === TOAST_OPTIONS.RESET_SUCCESSFUL
    ) {
      this.makeToast(
        "success",
        this.$t("recoverPassMsgs.resetPasswordSuccess"),
        this.$t("generalMsgs.success"),
        "b-toaster-top-center"
      );
    }
    this.updateResetPasswordTokenInvalid(null);
  },
};
</script>
